import { RefObject, useRef } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { VolumeRequirementTile } from "../../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";
import { createLoadingTiles, Options, TempAnnualRequirements, TempMonthlyRequirements } from "../LSRequirements";
import defaultContent from "../../../../content/leadershipSeminar";
import { VcsPercentRequirementTile } from "../../../../common/components/RequirementTiles/VcsPercentRequirementTile/VcsPercentRequirementTile";
import { BadgeRequirementTile } from "../../../../common/components/RequirementTiles/BadgeRequirementTile/BadgeRequirementTile";
import { FQsComparisonTile } from "../../FQsComparisonTile/FQsComparisonTile";
import "./OptionsLayout.scss";
import { IconPlainTable } from "../../../../common/icons/IconPlainTable/IconPlainTable";

type Props = {
  options: Options;
  isLoading: boolean;
};

const mapMonthlyRequirements = (
  monthlyRequirements: TempMonthlyRequirements,
  translate: TFunction,
  isLoading: boolean,
) => {
  if (isLoading) {
    const totalRequirements = Math.min(monthlyRequirements.TotalRequirements, 2);
    return createLoadingTiles(totalRequirements);
  }

  const volumeRequirements = monthlyRequirements.MonthlyRubyRequirement.map((requirement) => {
    return (
      <section className="OptionsLayout__tile" key={requirement.Name} data-testid={requirement.Name + "-Tile"}>
        <VolumeRequirementTile
          isLoading={false}
          title={translate(
            `requirements.${requirement.Name}.title`,
            defaultContent["requirements"][requirement.Name]["title"] as string,
            {
              volumeMonthlyGpv: requirement.Volume.Target.Formatted,
            },
          )}
          currentAmount={requirement.Volume.Current.Actual}
          targetAmount={requirement.Volume.Target.Actual}
          met={requirement.RequirementsMet.NoLegPVMet}
          circleCheckmarkId={requirement.Name}
          volumeType={requirement.Name}
          labels={[requirement.Volume.Current.Formatted, requirement.Volume.Remaining.Formatted]}
          showLabelsOnly={true}
          additionalNeeded={requirement.Volume.Remaining.Formatted}
          exempt={requirement.Exempt}
        />
      </section>
    );
  });
  const badgeRequirements = monthlyRequirements.BadgeRequirements?.map((requirement) => {
    return (
      <section key={requirement.Name} data-testid={origin + "Tile-" + requirement.Name}>
        <BadgeRequirementTile
          title={translate(
            `requirements.${requirement.Name}.title`,
            defaultContent["requirements"][requirement.Name]["title"] as string,
            {
              bonusAmount: requirement.Target,
            },
          )}
          description={translate(
            `requirements.${requirement.Name}.description`,
            defaultContent["requirements"][requirement.Name]["description"] as string,
          )}
          status={requirement.Status}
          showLabel={requirement.ShowLabel}
        />
      </section>
    );
  });
  return (
    <>
      {volumeRequirements}
      {badgeRequirements}
    </>
  );
};

const mapAnnualRequirements = (
  annualRequirements: TempAnnualRequirements,
  buttonRef: RefObject<HTMLButtonElement>,
  translate: TFunction,
  isLoading: boolean,
) => {
  if (isLoading) {
    const totalRequirements = Math.min(annualRequirements.TotalRequirements, 3);
    return createLoadingTiles(totalRequirements);
  }

  const renderVolumeRequirements = () =>
    annualRequirements.VolumeRequirements.map(({ Name, Volume, Met, Exempt }) => (
      <section key={Name} data-testid={`${origin}Tile-${Name}`}>
        <VolumeRequirementTile
          isLoading={false}
          title={translate(`requirements.${Name}.title`, defaultContent["requirements"][Name]["title"] as string, {
            volumeAnnualGpv: Volume.Target.Formatted,
          })}
          currentAmount={Volume.Current.Actual}
          targetAmount={Volume.Target.Actual}
          met={Met}
          circleCheckmarkId={Name}
          volumeType={Name}
          labels={[Volume.Current.Formatted, Volume.Remaining.Formatted]}
          showLabelsOnly={true}
          additionalNeeded={Volume.Remaining.Formatted}
          exempt={Exempt}
          buttonData={{
            clickFunction: () => console.log("update this with modal open"),
            text: "Monthly GPV Status",
            ref: buttonRef,
            icon: <IconPlainTable />,
          }}
        />
      </section>
    ));

  const renderPercentRequirements = () =>
    annualRequirements.PercentRequirements.map(({ Name, Percent, Volume, Met }) => (
      <section key={Name} data-testid={`${origin}Tile-${Name}`}>
        <VcsPercentRequirementTile
          isLoading={false}
          additionalNeeded={Percent.Remaining.Actual}
          targetValue={Percent.Target.Actual}
          met={Met}
          shouldShowGraphics={true}
          leftValue={Volume.Current.Actual}
          rightValue={Volume.Target.Actual}
          percent={Percent.Current.Actual}
        />
      </section>
    ));

  const renderBadgeRequirements = () =>
    annualRequirements.BadgeRequirements?.map(({ Name, Target, Status, ShowLabel }) => (
      <section key={Name} data-testid={`${origin}Tile-${Name}`}>
        <BadgeRequirementTile
          title={translate(`requirements.${Name}.title`, defaultContent["requirements"][Name]["title"] as string, {
            bonusAmount: Target,
          })}
          description={translate(
            `requirements.${Name}.description`,
            defaultContent["requirements"][Name]["description"] as string,
          )}
          status={Status}
          showLabel={ShowLabel}
        />
      </section>
    )) ?? <></>;

  const renderFqRequirement = () =>
    annualRequirements.FQRequirement ? (
      <FQsComparisonTile
        isLoading={false}
        hideBorder={false}
        showFqTileByStack={true}
        fqStatusesMet={[true, true, false]}
        totalFqs={3}
        met={false}
        status={"8"}
        tracking={"10"}
        currentYtd={"10+"}
      />
    ) : (
      <></>
    );

  return (
    <>
      {renderVolumeRequirements()}
      {renderPercentRequirements()}
      {renderBadgeRequirements()}
      {renderFqRequirement()}
    </>
  );
};

export const OptionsLayout = ({ options, isLoading }: Props) => {
  const { t } = useTranslation("leadershipSeminar");
  const { FirstOption, SecondOption } = options;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { AnnualRequirements: firstOptionAnnualReqs, MonthlyRequirements: firstOptionMonthlyReqs } = FirstOption;
  const { AnnualRequirements: secondOptionAnnualReqs, MonthlyRequirements: secondOptionMonthlyReqs } = SecondOption;
  return (
    <div className="OptionsLayout">
      <section className="OptionsLayout__Section">
        <h4 className="OptionsLayout--h4">
          {t(`requirements.optionOne`, defaultContent["requirements"]["optionOne"] as string)}
        </h4>
        <div className="OptionsLayout__tiles">
          {mapAnnualRequirements(firstOptionAnnualReqs, buttonRef, t, isLoading)}
          {mapMonthlyRequirements(firstOptionMonthlyReqs, t, isLoading)}
        </div>
      </section>

      <section className="OptionsLayout__Section">
        <h4 className="OptionsLayout--h4">
          {t(`requirements.optionTwo`, defaultContent["requirements"]["optionTwo"] as string)}
        </h4>
        <div className="OptionsLayout__tiles">
          {mapAnnualRequirements(secondOptionAnnualReqs, buttonRef, t, isLoading)}
          {mapMonthlyRequirements(secondOptionMonthlyReqs, t, isLoading)}
        </div>
      </section>
    </div>
  );
};
