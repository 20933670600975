import { formatCurrency } from "@amwaycommon/acc-mixins/l10n";
import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../../content/newAboActivation";
import { storedLocale } from "../../../impersonation/util";
import { PathToBronzeData } from "../../../../common/interfaces/pathToBronze";
import { getLegByLegNumber } from "../pathToBronzeUtils";
import { getFullMonth } from "../../../../common/util/period";
import { learnMoreOnClick } from "../../../../common/tealium/BronzeIncentive/PathToBronze/LearnMoreButtonTrack";
import { getBronzeDisplayDate } from "../../BronzeCommon/BronzeDate/BronzeDate";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import LeafIncentiveBannerTile from "../../../../common/leaf/LeafIncentiveBannerTile/LeafIncentiveBannerTile";
import "./PtbBannerTile.scss";
import { useAppSelector } from "../../../../store";

type Props = {
  data: PathToBronzeData;
  isLoading: boolean;
  selectedPeriod: string;
  extraBonusesMet: number;
};

export const PtbBannerTile = ({ data, isLoading, selectedPeriod, extraBonusesMet }: Props) => {
  const { t } = useTranslation(["newAboActivation"]);
  const locale = storedLocale();
  const {
    earning,
    denied,
    manualApproval,
    held,
    bonus,
    bonusCurrency,
    legRequirements,
    personalVolume,
    perfBonusLevel,
    country,
  } = data;
  const learnMoreLink = useAppSelector((store) => store.boot?.configuration?.dashboardLinks?.learnMore?.pathToBronze);
  const bonusAmountToFormat = earning || held || manualApproval ? bonus : 0;
  const firstLegReq = getLegByLegNumber(legRequirements, 1);
  const maxBonus = getLegByLegNumber(legRequirements, legRequirements.length);
  const formattedBonusAmount = formatCurrency(bonusAmountToFormat, bonusCurrency, locale, country, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
  });
  const maxBonusAvailable = formatCurrency(maxBonus.bonusAmount, bonusCurrency, locale, country, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
  });

  const __getAllReqsMetStatus = () => {
    if (firstLegReq && personalVolume && perfBonusLevel) {
      return (firstLegReq.met && personalVolume.met && (perfBonusLevel.met || !perfBonusLevel.required)) || false;
    }
    return false;
  };
  const allReqsMet = __getAllReqsMetStatus();

  // Derive status based on `data` fields
  const derivedStatus = (() => {
    if (data.denied) return QUALIFICATION_STATUS.DENIED;
    if (data.held) return QUALIFICATION_STATUS.HELD;
    if (data.manualApproval) return QUALIFICATION_STATUS.MANUALLY_QUALIFIED;
    if (data.earning && !extraBonusesMet) return QUALIFICATION_STATUS.QUALIFIED;
    if (data.earning && allReqsMet) return QUALIFICATION_STATUS.ELIGIBLE;
    if (data.ineligibleForPY || data.graduated) return QUALIFICATION_STATUS.NOT_ELIGIBLE;
    return QUALIFICATION_STATUS.NOT_QUALIFIED;
  })();

  const bannerMessages = [
    {
      status: [QUALIFICATION_STATUS.QUALIFIED],
      title: `${t("earningIncentiveTitle", defaultContent["earningIncentiveTitle"])}`,
      subtitle: `${t("downlineGrowthDescription", defaultContent["downlineGrowthDescription"])}`,
      description: (
        <p className="PtbBannerTile__formatBonusAmount">
          <Trans>
            {`${t("bonusAmountDescription", defaultContent["bonusAmountDescription"], {
              amount: maxBonusAvailable,
            })}`}
            <span></span>
          </Trans>
        </p>
      ),
    },
    {
      status: [QUALIFICATION_STATUS.ELIGIBLE],
      title: `${t("allReqsMet", defaultContent["allReqsMet"])}`,
      subtitle: `${t("downlineGrowthDescription", defaultContent["downlineGrowthDescription"])}`,
    },
    {
      status: [QUALIFICATION_STATUS.NOT_QUALIFIED],
      title: `${t("notEarningIncentiveTitle", defaultContent["notEarningIncentiveTitle"])}`,
      subtitle: `${t("downlineGrowthDescription", defaultContent["downlineGrowthDescription"])}`,
      description: (
        <p className="PtbBannerTile__formatBonusAmount">
          <Trans>
            {`${t("bonusAmountDescription", defaultContent["bonusAmountDescription"], {
              amount: maxBonusAvailable,
            })}`}
            <span></span>
          </Trans>
        </p>
      ),
    },
    {
      status: [QUALIFICATION_STATUS.DENIED],
      title: `${t("deniedIncentiveTitle", defaultContent["deniedIncentiveTitle"], {
        month: getFullMonth(selectedPeriod, locale),
      })}`,
      subtitle: `${t("downlineGrowthDescription", defaultContent["downlineGrowthDescription"])}`,
      description: (
        <p className="PtbBannerTile__formatBonusAmount">
          <Trans>
            {`${t("bonusAmountDescription", defaultContent["bonusAmountDescription"], {
              amount: maxBonusAvailable,
            })}`}
            <span></span>
          </Trans>
        </p>
      ),
    },
    {
      status: [QUALIFICATION_STATUS.HELD],
      title: `${t("onHoldIncentiveTitle", defaultContent["onHoldIncentiveTitle"])}`,
      subtitle: `${t("customerCareContactDescription", defaultContent["customerCareContactDescription"])}`,
    },
    {
      status: [QUALIFICATION_STATUS.MANUALLY_QUALIFIED],
      title: `${t("manuallyApprovedIncentiveTitle", defaultContent["manuallyApprovedIncentiveTitle"])}`,
      subtitle: `${t("customerCareContactDescription", defaultContent["customerCareContactDescription"])}`,
    },
    {
      status: [QUALIFICATION_STATUS.NOT_ELIGIBLE],
      title: `${t("ineligibleTitle", defaultContent["ineligibleTitle"], {
        dateText: getBronzeDisplayDate(selectedPeriod),
      })}`,
      subtitle: `${t("ineligibleDescription", defaultContent["ineligibleDescription"], {
        achieveLimit: data.awardCap.target,
      })}`,
    },
  ];

  const statusToHideIncentive = [
    QUALIFICATION_STATUS.DENIED,
    QUALIFICATION_STATUS.NOT_ELIGIBLE,
    QUALIFICATION_STATUS.HELD,
  ];

  return (
    <LeafIncentiveBannerTile
      bannerMessages={bannerMessages}
      incentive={{
        title: `${t("aboActivationLabel", defaultContent["aboActivationLabel"])}`,
        status: derivedStatus,
        amount: formattedBonusAmount,
        statusToHideIncentive,
      }}
      learnMoreLink={learnMoreLink}
      isLoading={isLoading}
      isCompactView={true}
      learnMoreTealiumFunction={learnMoreOnClick}
    />
  );
};
