import { useTranslation } from "react-i18next";
import { PathToBronzeData } from "../../../../common/interfaces/pathToBronze";
import LeafTile, { Props as LeafTileProps } from "../../../../common/leaf/LeafTile/LeafTile";
import LeafButton from "../../../../common/leaf/LeafButton/LeafButton";
import defaultContent from "../../../../content/newAboActivation";
import { storedLocale } from "../../../impersonation/util";
import "./EligibilityTile.scss";
import { getLongPeriodFormat, getSelectedPerformanceYearData } from "../../../../common/util/period";
import { getBronzeDisplayDate } from "../../BronzeCommon/BronzeDate/BronzeDate";

type EligibilityTileProps = {
  data: PathToBronzeData;
  period: string;
  showEligibilityButton: boolean;
  handleButtonClick: Function;
} & LeafTileProps;

function EligibilityTile({ data, isLoading, period, showEligibilityButton, handleButtonClick }: EligibilityTileProps) {
  const { t } = useTranslation(["newAboActivation"]);
  const locale = storedLocale();
  const { selectedPYEndPeriod } = getSelectedPerformanceYearData(period, 0);
  const monthYYYY = getLongPeriodFormat(selectedPYEndPeriod, locale);

  const { ineligibleForPY, awardCap, perfBonusLevel, graduated } = data;

  const BonusAmountDescription = () => {
    return (
      <div>
        {`${t("eligibileDescription", defaultContent["eligibileDescription"])}`}
        <span className="ptbEligibilityTile__formatRemainingTimes">
          {`${t("eligibileDescriptionRemaining", defaultContent["eligibileDescriptionRemaining"], {
            remainingTimes: awardCap.remaining,
          })}`}
        </span>
        {`${t("eligibileDescriptionPeriod", defaultContent["eligibileDescriptionPeriod"], { monthYYYY })}`}
      </div>
    );
  };

  const ineligibleDescription = t("ineligibleDescription", defaultContent["ineligibleDescription"], {
    achieveLimit: awardCap.target,
  });

  const getContent = () => {
    let title;
    let description;
    if (perfBonusLevel.required || awardCap.target > 0) {
      title = `${t("eligibleTitle", defaultContent["eligibleTitle"])}`;
      description = <BonusAmountDescription />;
    }
    return { title, description };
  };

  return (
    <>
      {!ineligibleForPY && !graduated ? (
        <LeafTile
          title={getContent().title}
          description={getContent().description}
          isLoading={isLoading}
          circleCheckmarkId=""
          id="ptbEligibilityTile"
        >
          {showEligibilityButton && (
            <LeafButton clickAction={handleButtonClick} id="ptbEligibilityButton">
              {`${t("viewEligibility", defaultContent["viewEligibility"])}`}
            </LeafButton>
          )}
        </LeafTile>
      ) : null}
    </>
  );
}

export default EligibilityTile;
